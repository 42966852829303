import { useState, useEffect } from "react";
// import axios from "axios";

export const Clock  = () => {
  // Initialize the current time to the current date and time
  const [time, setTime] = useState(new Date());


  // const [posts, setPosts] = useState([]);

  // useEffect(() => {
  //   axios.get('http://127.0.0.1:8000/posts/')
  //     .then(response => {
  //       if (response.data) {
  //         setPosts(response.data);
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Error fetching posts:", error);
  //     });
  // }, []);

  // console.log(posts);

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // Extract the hours, minutes, and seconds from the current time
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  // Format the time as a string
  const timeString = `${hours}:${minutes}:${seconds}`;

    return (
        <div>
        {/* Display the time string */}
        <h4 className="text-center">{timeString}</h4>
  {/*<hr/>*/}
  {/*        <div>*/}
  {/*          {posts.length > 0 ? (*/}
  {/*            <div>*/}
  {/*              {posts.map((post, id) => (*/}
  {/*                <div key={id}>*/}
  {/*                  <h1>{post.title}</h1>*/}
  {/*                  <p>{post.body}</p>*/}
  {/*                  <p>{post.owner}</p>*/}
  {/*                </div>*/}
  {/*              ))}*/}
  {/*            </div>*/}
  {/*          ) : (*/}
  {/*            <p>No posts available</p>*/}
  {/*          )}*/}
  {/*        </div>*/}

      </div>
    );
}

